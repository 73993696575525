var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-lg-none d-block py-1 bg-white"},[_c('b-link',{staticClass:"d-flex justify-content-center"},[_c('b-img',{staticClass:"bg-white logo_img",attrs:{"src":require("./../../assets/images/avatars/image.png"),"rounded":"","fluid":"","alt":"profile photo"}})],1)],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-start mt-0",attrs:{"lg":"6"}},[_c('div',{staticClass:"d-lg-flex justify-content-center sticky-top"},[_c('div',{staticClass:"mb-5"},[_c('b-link',{staticClass:"brand-logo pb-5"},[_c('img',{attrs:{"src":require("./../../assets/images/logo/vep-logo.png"),"alt":""}})])],1),_c('div',{staticClass:"p-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)])]),_c('b-col',{staticClass:"auth-bg px-2 p-lg-5 pt-lg-0 pt-2 overflow-auto",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"mx-auto mb-1",attrs:{"sm":"12","md":"10","lg":"10"}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('b-card-title',{staticClass:"fw-bold mb-0",attrs:{"title-tag":"h4"}},[_vm._v(" Present/Last Qualification Details ")]),_c('p',{staticClass:"text-primary m-0"},[_c('u',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.skipped}},[_vm._v("Skip, I will add later")])])],1),_c('hr',{staticClass:"mb-1"})]),_c('b-col',{staticClass:"px-2 mx-auto pb-5",attrs:{"sm":"12","md":"10","lg":"10"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitQualification($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Degree","label-for":"degree"}},[_c('validation-provider',{attrs:{"name":"Degree","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"degree","state":errors.length > 0 ? false : null,"placeholder":"Degree"},model:{value:(_vm.form.degree),callback:function ($$v) {_vm.$set(_vm.form, "degree", $$v)},expression:"form.degree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Field Of Study","label-for":"field Of study"}},[_c('validation-provider',{attrs:{"name":"Field Of Study","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"field_of_study","state":errors.length > 0 ? false : null,"placeholder":"Field of Study"},model:{value:(_vm.form.field_of_study),callback:function ($$v) {_vm.$set(_vm.form, "field_of_study", $$v)},expression:"form.field_of_study"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name Of Institution","label-for":"Name Of Institution"}},[_c('validation-provider',{attrs:{"name":"Name Of Institution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"institution_name","state":errors.length > 0 ? false : null,"placeholder":"Name Of Institution"},model:{value:(_vm.form.institution_name),callback:function ($$v) {_vm.$set(_vm.form, "institution_name", $$v)},expression:"form.institution_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Is the Course Completed ?"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"value":"true"},model:{value:(_vm.form.completed),callback:function ($$v) {_vm.$set(_vm.form, "completed", $$v)},expression:"form.completed"}},[_vm._v(" Yes ")]),_c('b-form-radio',{attrs:{"value":"false"},model:{value:(_vm.form.completed),callback:function ($$v) {_vm.$set(_vm.form, "completed", $$v)},expression:"form.completed"}},[_vm._v(" No ")])],1)])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Start Date"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","date-format-options":{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    },"placeholder":"Choose a date","local":"en"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1)],1),(_vm.form.completed === 'true')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"End Date"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder2","date-format-options":{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    },"placeholder":"Choose a date","local":"en"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{attrs:{"id":"textarea-rows","placeholder":"Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"my-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Next ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }