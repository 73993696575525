<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 overflow-auto">
        <b-col sm="12" md="10" lg="10" class="mx-auto mb-1">
          <!-- <b-card-title title-tag="h4" class="font-weight-bold m-0">
            Present/Last Qualification Details
          </b-card-title> -->
          <div class="d-flex justify-content-between mb-1">
            <b-card-title title-tag="h4" class="fw-bold mb-0">
              Present/Last Qualification Details
            </b-card-title>
            <p class="text-primary m-0">
              <u style="cursor: pointer" @click="skipped"
                >Skip, I will add later</u
              >
            </p>
          </div>
          <hr class="mb-1" />
        </b-col>
        <!-- form -->
        <b-col sm="12" md="10" lg="10" class="px-2 mx-auto pb-5">
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="submitQualification">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Degree" label-for="degree">
                    <validation-provider
                      #default="{ errors }"
                      name="Degree"
                      rules="required"
                    >
                      <b-form-input
                        id="degree"
                        v-model="form.degree"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Degree"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Field Of Study"
                    label-for="field Of study"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Field Of Study"
                      rules="required"
                    >
                      <b-form-input
                        id="field_of_study"
                        v-model="form.field_of_study"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Field of Study"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Name Of Institution"
                    label-for="Name Of Institution"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name Of Institution"
                      rules="required"
                    >
                      <b-form-input
                        id="institution_name"
                        v-model="form.institution_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name Of Institution"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Is the Course Completed ?">
                    <div class="demo-inline-spacing">
                      <b-form-radio v-model="form.completed" value="true">
                        Yes
                      </b-form-radio>
                      <b-form-radio v-model="form.completed" value="false">
                        No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Start Date">
                    <b-form-datepicker
                      id="datepicker-placeholder"
                      v-model="form.start_date"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      placeholder="Choose a date"
                      local="en"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="form.completed === 'true'">
                  <b-form-group label="End Date">
                    <b-form-datepicker
                      id="datepicker-placeholder2"
                      v-model="form.end_date"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      placeholder="Choose a date"
                      local="en"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Description">
                    <b-form-textarea
                      id="textarea-rows"
                      v-model="form.description"
                      placeholder="Description"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>

                <!-- next button -->
                <b-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    class="my-2"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Next
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { required } from "@validations"
import {
  BButton,
  BCardTitle,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BImg,
  BLink,
  BRow,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormRadio,
    BFormGroup,
    BFormInput,

    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        degree: null,
        field_of_study: null,
        institution_name: null,
        completed: "false",
        start_date: null,
        end_date: null,
        description: null,
      },

      sideImg: require("@/assets/images/pages/register-v2.svg"),

      // validation
      required,

      selected: null,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
    token() {
      return store.state.auth.access_token
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          store
            .dispatch("auth/qualificationDetails", this.form)
            .then((response) => {
              if (response.data.status === "success") {
                this.$router.push({ name: "auth-present-job" })

                // notified
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Qualification Submitted",
                    icon: "EditIcon",
                    variant: "success",
                  },
                })
              }
            })
        }
      })
    },

    skipped() {
      this.$router.push({ name: "auth-present-job" })
    },
    submitQualification() {},
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
.logo_img {
  width: 120px !important;
  margin-left: -80px;
}
</style>
